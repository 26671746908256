/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Layout from '../layout';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';
import * as messages from '../locales/en.json';
import useAnalytics from '../hooks/useAnalytics';

// components
import Tabs from '../components/common/Tabs/Tabs';

function PrivacyPageCA() {
  const { trackEvent } = useAnalytics();

  return (
    <Layout hideNYResidentsDisclosure hideAccountOwner hideNMLS>
      <div className="landing-container">
        <SEO metadata={{ title: config.privacyTitle }} />
        <section className="privacy-policies container">
          <Tabs
            options={messages['privacy.tabs']}
            onClick={() => trackEvent('Click Privacy Policy Tab', { Type: 'General' })}
            activeTab={1}
          />
          <div className="privacy-content">
            <section className="privacy-header">
              <h1>{messages['privacy.ca.title']}</h1>
            </section>
            <div className="content">
              <ol>
                <li>
                  <h2>Purpose</h2>
                  <p>
                    This Privacy Policy is for California residents and discloses the privacy
                    practices of Spring Oaks Capital, LLC and its affiliates, including Spring Oaks
                    Capital, SPV. (collectively “Spring Oaks”, “us,” or “we”). This policy
                    supplements the information contained in the Spring Oaks Privacy Policy, which
                    can be found at{' '}
                    <a href="https://springoakscapital.com/">springoakscapital.com</a>. We adopt
                    this policy to comply with the California Consumer Privacy Act (“CCPA”). Any
                    terms defined in the CCPA have the same meaning used in this policy.
                  </p>
                </li>
                <li>
                  <h2>Scope</h2>
                  <p>
                    This Policy covers the practices of Spring Oaks with respect to California
                    residents and applies to all nonpublic personally identifiable information,
                    including information contained in consumer reports, of our current and former
                    customers and the consumers with whom Spring Oaks interacts. The Spring Oaks
                    website is not intended for individuals less than the age of eighteen and we do
                    not knowingly collect data relating to individuals less than the age of
                    eighteen.
                  </p>
                  <p>
                    Nothing in this Privacy Notice is intended to contradict your rights under the
                    Fair Debt Collection Practices Act. Spring Oaks will not disclose any
                    information to third parties that is otherwise prohibited by the FDCPA.
                  </p>
                </li>
                <li>
                  <h2>Policy</h2>
                  <ol>
                    <li>
                      <b>Information We Collect About You</b>
                      <p>
                        We may collect and use the following personal information that identifies,
                        relates to, describes, references, is capable of being associated with, or
                        could reasonably be linked, directly or indirectly, with a consumer, device,
                        or household (“personal information”). We have collected the following
                        categories of personal information from consumers within the last 12 months:
                      </p>
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <b>Categories of Personal Information</b>
                            </th>
                            <th>
                              <b>Examples of Specific Types of Personal Information Collected</b>
                            </th>
                            <th>
                              <b>Collected</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>A. Identifiers </td>
                            <td>
                              A real name, alias, postal address, email address, telephone numbers,
                              Internet Protocol address, account number, Social Security number,
                              date of birth, or other similar identifiers.{' '}
                            </td>
                            <td>Yes</td>
                          </tr>
                          <tr>
                            <td>
                              B. Personal Information categories listed in California Customer
                              Records Statute (Cal. Civ. Code §1798.80(e)){' '}
                            </td>
                            <td>
                              A name, signature, Social Security number, physical characteristics or
                              description, address, telephone number, passport number, driver's
                              license or state identification card number, insurance policy number,
                              education, employment, employment history, bank account number, credit
                              card number, debit card number, or any other financial information,
                              medical information, or health insurance information. Some personal
                              information included in this category may overlap with other
                              categories.
                            </td>
                            <td>Yes</td>
                          </tr>
                          <tr>
                            <td>
                              C. Protected classification characteristics under California or
                              Federal law
                            </td>
                            <td>
                              Age (40 years or older), race, color, ancestry, national origin,
                              citizenship, religion or creed, marital status, medical condition,
                              physical or mental disability, sex (including gender, gender identity,
                              gender expression, pregnancy or childbirth and related medical
                              conditions), sexual orientation, veteran or military status, genetic
                              information (including familial genetic information).
                            </td>
                            <td>Yes</td>
                          </tr>
                          <tr>
                            <td>D. Commercial information </td>
                            <td>
                              Records of personal property, products or services purchased,
                              obtained, or considered, or other purchasing or consuming histories or
                              tendencies.
                            </td>
                            <td>Yes</td>
                          </tr>
                          <tr>
                            <td>E. Sensory data</td>
                            <td>
                              Audio, electronic, visual, thermal, olfactory, or similar information.
                            </td>
                            <td>Yes</td>
                          </tr>
                          <tr>
                            <td>F. Professional or employment-related information</td>
                            <td>Current or past job history.</td>
                            <td>Yes</td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        <b>Personal Information does not include:</b>
                        <br />
                        <ul>
                          <li>Publicly available information from government records.</li>
                          <li>Deidentified or aggregated consumer information.</li>
                          <li>
                            Information excluded from the CCPA's scope, like:
                            <ul>
                              <li>
                                Health or medical information covered by the Health Insurance
                                Portability and Accountability Act of 1996 (HIPAA) and the
                                California Confidentiality of Medical Information Act (CMIA) or
                                clinical trial data;
                              </li>
                              <li>
                                Personal information covered by certain sector-specific privacy
                                laws, including the Fair Credit Reporting Act (FRCA), the
                                Gramm-Leach-Bliley Act (GLBA) or California Financial Information
                                Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </p>
                    </li>
                    <li>
                      <b>How Your Personal Information is Collected</b>
                      <p>
                        We collect most of this personal information directly from our clients whom
                        we provide services to or when we purchase your account, as well as from you
                        by telephone, written correspondence through the mail, email or fax, by
                        viewing your public social media/network pages, or other information
                        available online. However, we may also collect information:
                      </p>
                      <ul>
                        <li>
                          From publicly accessible sources (e.g., property records or court
                          records);
                        </li>
                        <li>
                          From our service providers (e.g., letter vendor, skip tracing vendors,
                          payment processing vendors, call analytics vendor, and/or electronic
                          signature service provider);
                        </li>
                        <li>
                          Directly from a third party (e.g., third parties contacted during skip
                          tracing activities pursuant to 16 U.S.C. §1692b, such as your friends,
                          neighbors, relatives, and/or employer);
                        </li>
                        <li>
                          From a third party with your consent (e.g., your authorized representative
                          and/or attorney); and
                        </li>
                        <li>From activity on our website.</li>
                      </ul>
                    </li>
                    <li>
                      <b>How We Use Your Personal Information</b>
                      <p>
                        Personal information is collected solely for the purpose of debt recovery in
                        a lawful manner and remains part of our records until we determine the
                        information is no longer needed, or we are required by law to delete such
                        information. We will collect the minimum amount of data necessary to collect
                        a debt.{' '}
                      </p>
                      <p>
                        We will not collect additional categories of personal information or use the
                        personal information we collected for materially different, unrelated, or
                        incompatible purposes without providing you notice. We do not sell your
                        personal information.
                      </p>
                      <p>
                        We may also use or disclose your personal information for one or more of the
                        following business purposes:
                      </p>
                      <ul>
                        <li>
                          To fulfill or meet the reason you provided the information. For example,
                          if you share your personal information to make a payment, we will use that
                          information to process your payment.
                        </li>
                        <li>
                          To assist in collection of your account that we purchased or that we are
                          servicing for another party.
                        </li>
                        <li>To provide you with information that you request from us.</li>
                        <li>
                          Detecting security incidents, protecting against malicious, deceptive,
                          fraudulent, or illegal activity, and prosecuting those responsible for
                          that activity.
                        </li>
                        <li>
                          Debugging to identify and repair errors that impair existing intended
                          functionality.
                        </li>
                        <li>
                          To respond to law enforcement requests and as required by applicable law,
                          court order, or governmental regulations.
                        </li>
                        <li>
                          As necessary or appropriate to protect the rights, property or safety of
                          us, our clients, or others.
                        </li>
                        <li>
                          As described to you when collecting your personal information or as
                          otherwise set forth in the CCPA. <br />
                          <br />
                        </li>
                      </ul>
                      <p>
                        We will not collect additional categories of personal information or use the
                        personal information we collected for materially different, unrelated, or
                        incompatible purposes without providing you notice.
                      </p>
                    </li>
                    <li>
                      <b>Who We Share Your Personal Information With</b>
                      <p>
                        We may share personally identifiable information it collects with our
                        employees and affiliates who need to know that information to service your
                        account. Except as provided below, we do not share or disclose any
                        personally identifiable information to any company or marketing group
                        external to us. We may share your personal information with third parties
                        and service providers to the extent it is reasonably necessary to manage or
                        service your account, verify employment, determine location, process
                        payment, fulfill a transaction, provide customer service, or as otherwise
                        authorized by law.
                      </p>
                      <p>
                        Further, we may disclose personally identifiable information (i) to another
                        entity with which we enter or reasonably may enter into a corporate
                        transaction, such as, for example, a merger, consolidation, acquisition, or
                        asset purchase, (ii) to a third party pursuant to a subpoena, court order,
                        or other form of legal process or in response to a request by or on behalf
                        of any local, state, federal, or other government agency, department, or
                        body, whether or not pursuant to a subpoena, court order, or other form of
                        legal process, or in connection with litigation brought against, or on
                        behalf of, Spring Oaks, where appropriate, (iii) to a third party if
                        determined by Spring Oaks in its sole judgment that such disclosure is
                        appropriate to protect the life, health, or property of Spring Oaks or any
                        other person or entity, all in compliance with applicable law, (iv) to third
                        parties as authorized or designated by you, or (v) to conduct any other
                        legitimate business activity not otherwise prohibited by law. The foregoing
                        is not intended to obviate or displace any legal obligations or duties
                        applicable to Spring Oaks.
                      </p>
                      <p>
                        Except as necessary for us to provide the services, information, or products
                        requested by a website user, or except for the disclosures identified in the
                        preceding paragraphs, the user may opt out of having his or her personally
                        identifiable information, which has been voluntarily provided to us through
                        or from its website, prospectively retained by us, used by us for secondary
                        purposes, or disclosed by us to third parties.
                      </p>
                      <p>
                        E-mail posted or sent to us may not be secure against interception by
                        unauthorized individuals. To protect against interception by unauthorized
                        individuals, or because we can not verify your identity, we may be unable to
                        respond to e-mail requests concerning accounts placed for collection unless
                        you have requested or authorized us to do so.
                      </p>
                      <p>Sharing your information with Consumer Reporting Agencies</p>
                      <p>
                        Consumer Reporting Agencies (CRAs) collect and maintain information on
                        consumer and business credit profiles on behalf of organizations in the
                        United States. We may share information about you with CRAs and may carry
                        out periodic searches with them to verify your identity or manage your
                        account.
                      </p>
                      <p>
                        Details of your account(s) with us may be sent to CRAs and recorded by them.
                        This information may be supplied by CRAs and may be used and searched by us
                        and other organizations, such as debt collection agencies, in order to:
                      </p>
                      <ul>
                        <li>consider applications for credit and credit related services;</li>
                        <li>locate debtors and recover debts; and</li>
                        <li>manage your accounts.</li>
                      </ul>
                      <p>
                        Spring Oaks may furnish account information to Experian, Equifax, and Trans
                        Union. You have a right to obtain an annual copy of your credit file from
                        CRAs by visiting{' '}
                        <a href="https://www.annualcreditreport.com/index.action">
                          www.annualcreditreport.com
                        </a>
                        .
                      </p>
                    </li>
                    <li>
                      <b>Your Rights</b>
                      <p>
                        You have the right under the California Consumer Privacy Act of 2018 (CCPA)
                        and certain other privacy and data protection laws, as applicable, to
                        exercise free of charge:
                      </p>
                      <ol>
                        <li>
                          <b>Disclosure of Personal Information We Collect About You</b>
                          <p>
                            You have the right to request that we disclose certain information to
                            you about our collection and use of your personal information over the
                            past 12 months. Once we receive and confirm your verifiable consumer
                            request, we will disclose to you:
                          </p>
                          <ul>
                            <li>
                              The categories of personal information we have collected about you.
                            </li>
                            <li>
                              The categories of sources from which the personal information is
                              collected.
                            </li>
                            <li>
                              Our business or commercial purpose for collecting or selling personal
                              information.
                            </li>
                            <li>
                              The categories of third parties with whom we share personal
                              information, if any.
                            </li>
                            <li>
                              The specific pieces of personal information we have collected about
                              you.
                            </li>
                          </ul>
                          <p>Please note that we are not required to:</p>
                          <ul>
                            <li>
                              Retain any personal information about you that was collected for a
                              single one-time transaction if, in the ordinary course of business,
                              that information about you is not retained;
                            </li>
                            <li>
                              Reidentify or otherwise link any data that, in the ordinary course of
                              business, is not maintained in a manner that would be considered
                              personal information; or
                            </li>
                            <li>
                              Provide the personal information to you more than twice in a 12-month
                              period.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>
                            Disclosure of Personal Information Sold or Used for a Business Purpose
                          </b>
                          <p>
                            In connection with any personal information we may sell or disclose to a
                            third party for a business purpose, you have the right to know:
                          </p>
                          <ul>
                            <li>
                              The categories of personal information about you that we sold and the
                              categories of third parties to whom the personal information was sold;
                              and
                            </li>
                            <li>
                              The categories of personal information that we disclosed about you for
                              a business purpose.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>Deletion Request Rights</b>
                          <p>
                            You have the right to request that we delete any of your personal
                            information that we collected from you and retained, subject to certain
                            exceptions. Once we receive and confirm your verifiable consumer
                            request, we will delete (and direct our service providers to delete)
                            your personal information from our records, unless an exception applies.
                            <br />
                            We may deny your deletion request if retaining the information is
                            necessary for us or our service providers to:
                          </p>
                          <ul>
                            <li>
                              Complete the transaction for which the personal information was
                              collected, provide a good or service requested by you, or reasonably
                              anticipated within the context of our ongoing business relationship
                              with you, or otherwise perform a contract between you and us.
                            </li>
                            <li>
                              Detect security incidents, protect against malicious, deceptive,
                              fraudulent, or illegal activity; or prosecute those responsible for
                              that activity.
                            </li>
                            <li>
                              Debug to identify and repair errors that impair existing intended
                              functionality.
                            </li>
                            <li>
                              Exercise free speech, ensure the right of another consumer to exercise
                              his or her right of free speech, or exercise another right provided
                              for by law.
                            </li>
                            <li>
                              Comply with the California Electronic Communications Privacy Act.
                            </li>
                            <li>
                              Engage in public or peer-reviewed scientific, historical, or
                              statistical research in the public interest that adheres to all other
                              applicable ethics and privacy laws, when our deletion of the
                              information is likely to render impossible or seriously impair the
                              achievement of such research, provided we have obtained your informed
                              consent.
                            </li>
                            <li>
                              Enable solely internal uses that are reasonably aligned with your
                              expectations based on your relationship with us.
                            </li>
                            <li>Comply with an existing legal obligation.</li>
                            <li>
                              Otherwise use your personal information, internally, in a lawful
                              manner that is compatible with the context in which you provided the
                              information.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>Protection Against Discrimination</b>
                          <p>
                            You have the right to not be discriminated against by us because you
                            exercised any of your rights under the CCPA. This means we cannot, among
                            other things:
                          </p>
                          <ul>
                            <li>Deny goods or services to you;</li>
                            <li>
                              Charge different prices or rates for goods or services, including
                              through the use of discounts or other benefits or imposing penalties;
                            </li>
                            <li>
                              Provide a different level or quality of goods or services to you; or
                            </li>
                            <li>
                              Suggest that you will receive a different price or rate for goods or
                              services or a different level or quality of goods or services.
                            </li>
                          </ul>
                          <p>
                            Please note that we may charge a different price or rate, or provide a
                            different level or quality of goods and/or services to you, if that
                            difference is reasonably related to the value provided to you by your
                            personal information.
                          </p>
                        </li>
                        <li>
                          <b>Authorized Agent</b>
                          <p>
                            You can designate an authorized agent to make any of these requests by
                            providing your express written authorization. We must be able to verify
                            your identity and the authorization must include the authorized agent’s
                            name, address, telephone number, and email address (for providing the
                            personal information collected or to respond to a request for deletion).
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>How to Exercise Your Rights</b>
                      <p>
                        To exercise the access, data portability, and deletion rights described
                        herein, please submit a verifiable consumer request to us by either:
                      </p>
                      <ul>
                        <li>Calling us, toll-free, at 866-281-3065; or</li>
                        <li>Write to us at P.O. Box 1216, Chesapeake, VA 23327-1216.</li>
                      </ul>
                      <p>
                        Please note that you may only make a data access or data portability
                        disclosure request twice within a 12-month period.
                      </p>
                    </li>
                    <li>
                      <b>Verifying Your Identity (i.e., verifiable consumer request)</b>
                      <p>
                        If you choose to contact directly by phone or in writing, you will need to
                        provide us with:
                      </p>
                      <ul>
                        <li>
                          Enough information to identify you (e.g., your full name, address and
                          customer or matter reference number);
                        </li>
                        <li>
                          Proof of your identity and address (e.g., a copy of your driving license
                          or passport and a recent utility or credit card bill); and
                        </li>
                        <li>
                          Describe your request with sufficient detail that allows us to properly
                          understand, evaluate and respond to it.
                        </li>
                      </ul>
                      <p>
                        We are not obligated to make a data access or data portability disclosure if
                        we cannot verify that the person making the request is the person about whom
                        we collected information, or is someone authorized to act on such person’s
                        behalf.
                      </p>
                      <p>
                        Any personal information we collect from you to verify your identity in
                        connection with you request will be used solely for the purposes of
                        verification.
                      </p>
                    </li>
                    <li>
                      <b>Response Timing and Format</b>
                      <p>
                        We endeavor to respond to a verifiable consumer request within 45 days of
                        its receipt. If we require more time (up to 90 days), we will inform you of
                        the reason and extension period in writing. If you have an account with us,
                        we will deliver our written response to that account. If you do not have an
                        account with us, we will deliver our written response by mail or
                        electronically, at your option. Any disclosures we provide will only cover
                        the 12-month period preceding the verifiable consumer request's receipt. The
                        response we provide will also explain the reasons we cannot comply with a
                        request, if applicable. For data portability requests, we will select a
                        format to provide your personal information that is readily useable and
                        should allow you to transmit the information from one entity to another
                        entity without hindrance.
                      </p>
                      <p>
                        We do not charge a fee to process or respond to your verifiable consumer
                        request unless it is excessive, repetitive, or manifestly unfounded. If we
                        determine that the request warrants a fee, we will tell you why we made that
                        decision and provide you with a cost estimate before completing your
                        request.
                      </p>
                    </li>
                    <li>
                      <b>Changes to our Privacy Notice</b>
                      <p>
                        We reserve the right to amend this privacy policy at our discretion and at
                        any time. When we make changes to this privacy policy, we will notify you by
                        email or through a notice on our website homepage.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h2>Auditing Procedures</h2>
                  <p>
                    Spring Oaks will periodically monitor electronic systems such as e-mail and
                    Internet. Any direct, indirect, or attempted violation of this Policy, by or on
                    behalf of a User, and any actual or attempted violation by a Third Party on
                    behalf of a User, shall be considered a violation of the Policy by the User, and
                    the User shall be held directly accountable. In the event that Spring Oaks
                    becomes aware that any User activity may have violated this Policy and/or
                    exposed Spring Oaks to civil or criminal liability, Spring Oaks reserves the
                    right to investigate such activity; monitor, collect evidence, and block access
                    to such material; and cooperate with legal authorities and Third Parties in
                    investigating any alleged violations of this Policy. Spring Oaks also reserves
                    the right to implement technical mechanisms to prevent policy violations
                    including electronic monitoring of systems such as e-mail and Internet. Users
                    who violate this Policy or any other Spring Oaks-published policies or standards
                    be subject to disciplinary action by Spring Oaks, up to and including immediate
                    termination from employment. In addition, conduct that is unlawful under
                    applicable laws may subject Users to civil and, in some cases, criminal
                    prosecution.
                  </p>
                </li>
                <li>
                  <h2>Links to Other Websites</h2>
                  <p>
                    Our website may contain links to enable you to visit other websites of interest
                    easily. However, once you have used these links to leave our site, you should
                    note that we do not have any control over that other website. Therefore, we
                    cannot be responsible for the protection and privacy of any information which
                    you provide while visiting such sites and such sites are not governed by this
                    privacy statement. You should exercise caution and look at the privacy statement
                    applicable to the website in question.
                  </p>
                </li>
                <li>
                  <h2>How We Protect Information</h2>
                  <p>
                    Spring Oaks has implemented physical, electronic, and procedural security
                    safeguards to protect against the unauthorized release of or access to personal
                    information. We employ internal and external system safeguards designed to
                    protect confidentiality and security of personal information. The
                    confidentiality of any communication or material transmitted to or from Spring
                    Oaks via the website or via e-mail cannot be, and is not, guaranteed. You
                    acknowledge that the technical processing and transmission of the website’s
                    content may be transferred unencrypted and involve: (a) transmissions over
                    various networks; and (b) changes to confirm and adapt to technical requirements
                    of connecting networks or devices. If any questions arise about security, please
                    contact us using the information provided above.
                  </p>
                </li>
                <li>
                  <h2>Frequency of Training and Testing</h2>
                  <p>
                    All Spring Oaks personnel will receive training on our company policies
                    applicable to their position upon hire or promotion. The same Spring Oaks
                    complete attestations and assessments on those policies upon completion of
                    training and on an annual basis.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PrivacyPageCA;
